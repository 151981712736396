
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { Inhibitor } from "../../models/reports/inhibitor";
import { formatTwoDecimal } from "../../utils/format";

export default Vue.extend({
  name: "PercentagePatientsProphylaxis",
  data: () => ({
    dataTable: [] as Inhibitor[],
    isLoading: true,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: "N",
          value: "n",
        },
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("basic.country"),
          value: "country",
        },
        {
          text: i18n.t(
            "reports.agsreports.PercentagePatientsProphylaxis.percent_of_under_18_prophylaxis"
          ),
          value: "under_18_Percentage",
          formatter: (val: number): number | string => formatTwoDecimal(val),
        },
        {
          text: i18n.t(
            "reports.agsreports.PercentagePatientsProphylaxis.preciseEstimate"
          ),
          value: "under_18_Precise",
        },
        {
          text: i18n.t(
            "reports.agsreports.PercentagePatientsProphylaxis.percent_of_over_18_prophylaxis"
          ),
          value: "over_18_Percentage",
          formatter: (val: number): number | string => formatTwoDecimal(val),
        },
        {
          text: i18n.t(
            "reports.agsreports.PercentagePatientsProphylaxis.preciseEstimate"
          ),
          value: "over_18_Precise",
        },
      ];
    },
  },
  methods: {
    getData(year: number) {
      this.isLoading = true;
      axios
        .get(`Report/WFH/OnProphylaxis?year=${year}`)
        .then((res) => {
          this.dataTable = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `Report/WFH/OnProphylaxis/export?year=${this.selectedSurvey.year}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t(
            "reports.agsreports.PercentagePatientsProphylaxis.title"
          )}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getData(this.selectedSurvey.year);
    },
  },
});
